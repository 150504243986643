<template>
  <div>
    <div v-if="state==null"><el-button type="primary" size="small" @click="start">开启监听</el-button></div>
    <div v-if="state=='wait'">等待设备连接中...<el-button type="text" size="small" @click="stop">停止</el-button></div>
    <div v-if="state=='connect'">设备已连接。<el-button type="text" size="small" @click="stop">停止</el-button></div>
  </div>
</template>

<script>
  var socket;
  if(!window.WebSocket){
      window.WebSocket = window.MozWebSocket;
  }
  export default {
    props: ['value', 'machineCode'],
    data () {
      return {
        state: null,
        channelId: null
      }
    },
    methods: {
      //开启监听
      start(){
        if(!this.machineCode){
          this.$message({
                message: '请先输入机器码',
                type: 'error'
              })
          return
        }
        if(window.WebSocket){
            socket = new WebSocket("ws://127.0.0.1:9999/ws");
            socket.onmessage = event=>{
              let data = JSON.parse(event.data)
              let type = data.type
              if(type == 'add'){
                let machineCode = data.machineCode
                if(this.machineCode == machineCode){
                  this.channelId = data.channelId
                  this.state = 'connect'
                  this.$emit('stateChange',this.state)
                }
              }else if(type == 'close'){
                let channelId = data.channelId
                if(channelId==this.channelId && this.state=='connect'){
                  this.channelId = null
                  this.state = 'wait'
                }
              }
            };
            socket.onopen = event=>{
              this.state = 'wait'
              this.$emit('stateChange',this.state)
            };
            socket.onclose = event=>{
              this.state = null
              this.$emit('stateChange',this.state)
            };
        }else{
            alert("您的浏览器不支持WebSocket协议！");
        }
      },
      stop(){
        if(socket)socket.close()
      }
    }
  }
</script>
<style scoped lang="scss">
</style>
